import React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import { useResolution, Button } from '@ecommerce/shared'
import Layout from '../../components/Layout'
import { PageContainer } from '../../components/PageContainer'
import { PgPageProps } from '../../types/PgPages'
import { UserMenuMobileNavbar } from '../../components/NavBar/UserMenuMobileNavbar'
import withPageTransition from '../../components/withPageTransition'

const cssPrefix = `NewPassword__`

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  .${cssPrefix}container {
    width: 310px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .${cssPrefix} {
      &title {
        margin-bottom: 43px;
      }
      &text {
        margin-bottom: 50px;
      }
      &button {
        width: 250px;
      }
    }
  }
`

const Success = (props: PgPageProps) => {
  const { isDesktop } = useResolution()
  const { currentCity } = props.pageContext
  const currentSlug = currentCity && currentCity.slug ? currentCity.slug : 'vina-del-mar'

  return (
    <Layout
      currentCity={currentCity}
      title="Cambiar contraseña"
      navbar={isDesktop ? undefined : UserMenuMobileNavbar({ city: currentCity })}
    >
      <PageContainer cardMaxWidthDesktop="723px" cardWidthDesktop="723px" cardMinWidthDesktop="723px">
        <PageWrapper>
          <div className={`${cssPrefix}container`}>
            <h2 className={`${cssPrefix}title`}>Cambiar contraseña</h2>
            <span className={`${cssPrefix}text`}>¡Tu contraseña ha sido reestablecida con éxito!</span>
            <Button
              btnType="secondary"
              className={`${cssPrefix}button`}
              onClick={() => navigate(`/${currentSlug}/my-account`)}
            >
              Volver
            </Button>
          </div>
        </PageWrapper>
      </PageContainer>
    </Layout>
  )
}

export default withPageTransition(Success)
